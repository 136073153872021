<template>
  <div>
    <portal to="title-page">
      <h1>Crear categoria</h1>
    </portal>
    <v-card
      class="mx-auto elevation-1"
    >
      <v-card-title >
        <v-icon
          large
          left
        >
          {{mdiPlus}}
        </v-icon>
        <span class="title font-weight-light">Datos</span>
        <v-btn small class="ml-auto" @click="$router.go(-1)"><v-icon>{{mdiArrowLeft}}</v-icon></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form
          ref="form"
          v-model="validForm"
          lazy-validation
        >
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                label="Idioma"
                :prepend-icon="mdiFlag"
                :value="language ? language.name : ''"
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
              v-model="item.name"
              :rules="nameRules"
              :prepend-icon="mdiText"
              required
              dense
              label="Nombre*" hint="Ingrese el nombre de la categoria" persistent-hint></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <p>Descripcion</p>
              <vue-editor
                id="description"
                :class="item.description != null && item.description == '' ? 'error' : ''"
                v-model="item.description"
                :editor-toolbar="customToolbar"
              ></vue-editor>
            </v-col>
            <v-col cols="12" md="12" class="pt-5">
              <v-btn
                :disabled="!validForm"
                color="primary"
                class="mr-4"
                @click="validateForm"
              >
                <v-icon>mdi-content-save</v-icon>
                Agregar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="-1"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>{{mdiClose}}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template> 
<script>
  import { mdiPlus, mdiArrowLeft, mdiFlag, mdiText, mdiClose } from '@mdi/js';
  import { VueEditor } from "vue2-editor";
  import Language from "@/models/Language.js";
  import CategoryExtra from '@/models/CategoryExtra';
  export default {
    name: "CategoryExtraCreate",
    components: { VueEditor },
    data() {
      return {
        languageModel:new Language,
        categoriesModel : new CategoryExtra,
        ///////////////////////////////////////////////////////
        mdiPlus:mdiPlus,
        mdiArrowLeft:mdiArrowLeft,
        mdiFlag:mdiFlag,
        mdiText:mdiText,
        mdiClose:mdiClose,
        validForm:true,
        language:null,
        customToolbar: [
          ["bold", "italic", "underline"],
          [{ list: "ordered" }, { list: "bullet" }],
          ["code-block"],
        ],
        nameRules: [
          v => !!v || 'El nombre es obligatorio',
        ],
        item:{
          name:null,
          description:null,
        },
        descriptionRules: [
          v => !!v || 'La descripcion es obligatoria',
          v => (v && v.length >= 10) || 'La descripcion debe ser de al menos 10 caracteres',
        ],
        snackbar:{
          show:false,
          color:'',
          message:''
        }
      }
    },
    created () {
      this.$store.commit('SET_LAYOUT', 'admin')
    },
    methods:{
      validateForm() {
        if (this.$refs.form.validate()) {
          let loading = this.$loading.show();
          this.item.status = 'active';
          this.categoriesModel.add({name:this.item.name,description:this.item.description, status:'active'}, this.language).then(() => {
            loading.hide()
            this.showSnackbarSuccess('La categoria se agrego correctamente');
            this.$router.push({path:'/backend/admin/categories-extras'})
          })
          .catch((error) => {
            console.log(error);
            loading.hide()
            this.showSnackbarError('Error al crear la categoria, por favor contacte al Administrador si el problema persiste');
          });
        }
      },
      showSnackbarSuccess(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'green';
      },
      showSnackbarError(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'red';
      },
    },
    mounted() {
      this.languageModel.getAll().onSnapshot(snap => {
        this.languages = {};
        snap.forEach( doc => {
          this.languages[doc.id] = doc.data();
        })
        this.language = this.languages.es;
      })
    },
  }
</script>
<style>
  #app .quillWrapper.error {
    background-color: transparent !important;
  }
  .quillWrapper.error > div {
    border-color: #ff5252;
    background-color: transparent;
  }
</style>